import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

import "../style/index.scss"
import PrenotaOra from "../components/prenotaOra"

const Metodo = ({ data }) => {
  return (
  <Layout page="metodo">
    <Seo
      title="Metodo"
      description="Consulenza psicologica, Terapia di coppia, Valutazione psicodiagnostica, Psicoterapia sensomotoria, EMDR - guarire dal trauma, Psicoterapia e caos, Psicoterapia cognitivo-comportamentale, Percorso breve per la riduzione dell’ansia"
    />
    <article id="metodo">
      <h1>Il Mio Metodo</h1>
      <section>
        <p>Nel mio lavoro ho scelto di utilizzare solo tecniche psicoterapeutiche empiricamente fondate e universalmente riconosciute come efficaci dalla comunit&agrave; scientifica: il mio obiettivo &egrave; garantire ai miei pazienti solo il meglio che la letteratura scientifica possa offrire, cos&igrave; da permettere loro di ottenere il miglior risultato nel pi&ugrave; breve tempo possibile.</p>
        <p>Ecco alcune delle tecniche di cui mi servo maggiormente:</p>
      </section>

      <section className="method-block">
        <h4>EMDR <small>(Eye Movement Desensitization and Reprocessing - ovvero Desensibilizzazione e Rielaborazione attraverso i Movimenti Oculari)</small></h4>
        <div className="with-pic">
          <div>
            <p>Come funziona<br/>Un trauma &egrave; un evento che suscita nella persona una fortissima scarica di emozioni, le quali restano impresse nella memoria anche molto tempo dopo l&rsquo;evento stesso. Questa&nbsp;<em>ferita emotiva</em> continua a fare male anche per anni, compromettendo significativamente la vita della persona, la quale si trova a rivivere continuamente emozioni che appartengono al passato, come se quel momento terribile non fosse mai passato. L&rsquo;EMDR &egrave; una tecnica specifica per l&rsquo;elaborazione e il superamento del trauma che agisce direttamente sulla ferita emotiva, cos&igrave; da annullarne l&rsquo;effetto nefasto sulla qualit&agrave; della vita della persona. In questo modo &egrave; possibile smettere di vivere nel passato e tornare a godersi il presente.</p>
            <p>Per quali disturbi viene utilizzata maggiormente</p>
            <ul>
                <li>
                    Disturbo da Stress Post-Traumatico
                </li>
                <li>
                    Trauma &ldquo;semplice&rdquo;
                </li>
                <li>
                    Trauma cumulativo
                </li>
            </ul>
          </div>
          <div className="pic-wrapper">
            <Img className="normal-size pic" fluid={data.methodPhoto1.childImageSharp.fluid} loading="eager" />
          </div>
        </div>
      </section>

      <section className="method-block">
        <h4>Schema Therapy</h4>
        <div className="with-pic">
          <div className="pic-wrapper">
            <Img className="normal-size pic" fluid={data.methodPhoto2.childImageSharp.fluid} loading="eager" />
          </div>
          <div>
            <p>Come funziona<br/>Ti &egrave; mai capitato di pensare &ldquo;non so perch&eacute; l&rsquo;ho fatto, non ero in me!&rdquo;. &Egrave; capitato a tutti almeno una volta di avere un comportamento che non sappiamo spiegare, come travolti da una spinta che ci ha invasi, impossibile da trattenere. Episodi di questo tipo capitano perch&eacute;, in quel momento, si &egrave; attivato in noi uno schema di funzionamento: uno schema &egrave; un modo di essere, un modo di funzionare e ognuno di noi ne ha pi&ugrave; di uno. La Schema Therapy serve a riconoscere e dare un nome a queste parti di noi, per essere in grado di capire come e perch&eacute; si attivano determinati schemi ed essere pi&ugrave; consapevoli di noi stessi, potendo finalmente vivere a tutto tondo la nostra vita.</p>
            <p>Per quali disturbi viene utilizzata maggiormente</p>
            <ul>
              <li>
                  Disturbi di personalit&agrave;
              </li>
              <li>
                  Disturbi del comportamento alimentare (anoressia nervosa, bulimia nervosa, binge eating&hellip;)
              </li>
              <li>
                  Disturbi dissociativi
              </li>
              <li>
                  Dipendenza affettiva
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="method-block">
        <h4>ER/p <small>(esposizione con prevenzione della risposta)</small></h4>
        <div className="with-pic">
          <div>
            <p>Come funziona<br/> Paura dei ragni? Ti senti soffocare quando entri in metropolitana o in ascensore? Ci sono specifici comportamenti che ti senti &ldquo;obbligato&rdquo; a fare per stare bene con te stesso? Il metodo ER/p &egrave; quello che fa per te! Si tratta di uno degli strumenti terapeutici pi&ugrave; efficaci in campo psicologico: &egrave; scientificamente dimostrato infatti che permette alle persone di ridurre sensibilmente i livelli di ansia nelle situazioni sopradescritte e in tante altre ancora.</p>
            <p>Per quali disturbi viene utilizzata</p>
            <ul>
                <li>
                    Fobie specifiche (aracnofobia, claustrofobia&hellip;)
                </li>
                <li>
                    Disturbo Ossessivo-Compulsivo
                </li>
            </ul>
          </div>
          <div className="pic-wrapper">
            <Img className="normal-size pic" fluid={data.methodPhoto3.childImageSharp.fluid} loading="eager" />
          </div>
        </div>
      </section>

      <section className="method-block">
        <h4>SensoryMotor</h4>
        <div className="with-pic">
          <div className="pic-wrapper">
            <Img className="normal-size pic" fluid={data.methodPhoto4.childImageSharp.fluid} loading="eager" />
          </div>
          <div>
            <p>Come funziona<br/>Ti &egrave; mai capitato di sentirti improvvisamente in ansia, ma senza capire quale sia il motivo? Ti &egrave; mai capitato di sentirti apparentemente &ldquo;tranquill*&rdquo; eppure con un senso generalizzato di malessere? Probabilmente il tuo corpo sta cercando di dirti qualcosa di molto importante!</p>
            <p>Il nostro corpo non &egrave; solo un poggiatesta, esso infatti ci parla, inviandoci costantemente messaggi importanti che, se saremo in grado di ascoltare, ci aiuteranno a vivere meglio la nostra quotidianit&agrave; ed a goderci serenamente i piaceri della vita.</p>
            <p>Per quali disturbi viene utilizzata</p>
            <ul>
                <li>
                    Disturbo da sintomi somatici
                </li>
                <li>
                    Attacchi di panico
                </li>
                <li>
                    Disturbi d&rsquo;ansia
                </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="method-block">
        <h4>ACT <small>(Acceptance and Commitment Therapy)</small></h4>
        <div className="with-pic">
          <div>
            <p>Come funziona<br/>La Terapia dell&rsquo;Accettazione e dell&rsquo;Impegno &egrave; un modello di psicoterapia di ultima generazione e si basa sul seguente principio: i nostri pensieri e le nostre emozioni ci fanno soffrire non tanto per il loro contenuto, ma piuttosto per il modo in cui ce ne serviamo. In sostanza la vera domanda da porsi non &egrave; &ldquo;cosa dice il tuo pensiero?&rdquo; ma &ldquo;cosa te ne fai di questo pensiero?&rdquo;.</p>
            <p>Imparare a rapportarsi in modo nuovo e pi&ugrave; funzionale con i nostri pensieri e le nostre emozioni &egrave; il modo migliore per iniziare una nuova vita.&nbsp;</p>
            <p>Per quali disturbi viene utilizzata</p>
            <ul>
                <li>
                    Rimuginazione
                </li>
                <li>
                    Disturbi di personalit&agrave;
                </li>
                <li>
                    Impulsivit&agrave;
                </li>
            </ul>
          </div>
          <div className="pic-wrapper">
            <Img className="normal-size pic" fluid={data.methodPhoto5.childImageSharp.fluid} loading="eager" />
          </div>
        </div>
      </section>

      <section className="method-block">
        <h4>La psicoterapia: un abito su misura per te!</h4>
        <p>Queste sono solo alcune delle tecniche di cui mi servo nell&rsquo;aiutare i miei pazienti, ma come si fa a stabilire quale sia la pi&ugrave; adatta a te e come sapere se funzioner&agrave; per risolvere il tuo problema? Fare psicoterapia &egrave; come farsi fare un abito da un sarto, deve essere costruito esattamente su misura ed &egrave; esattamente questo che faremo in studio: analizzeremo i tuoi bisogni e le tue esigenze nel modo pi&ugrave; accurato possibile, cos&igrave; avremo una visione completa di come intervenire, quali tecniche utilizzare e cosa aspettarci dal percorso. Prenota subito il tuo primo colloquio e inizia il tuo percorso di cambiamento!</p>
        <div className="pic-wrapper">
          <Img className="pic" fluid={data.methodPhoto6.childImageSharp.fluid} loading="eager" />
        </div>
        <div></div>
      </section>

      <section className="method-block">
        <PrenotaOra />
      </section>
    </article>
  </Layout>
  )
}

export default Metodo

export const query = graphql`
  query {
    methodPhoto1: file(relativePath: { eq: "kelly-sikkema-4le7k9XVYjE-unsplash.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    methodPhoto2: file(relativePath: { eq: "joshua-fuller-_I5DYcYuo6c-unsplash.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    methodPhoto3: file(relativePath: { eq: "vadim-bogulov-9kbNYzo1XtQ-unsplash.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    methodPhoto4: file(relativePath: { eq: "ahmad-odeh-KHipnBn7sdY-unsplash.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    methodPhoto5: file(relativePath: { eq: "tingey-injury-law-firm-LJhXYHxPfEY-unsplash.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    methodPhoto6: file(relativePath: { eq: "emma-simpson-mNGaaLeWEp0-unsplash.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
